import cc from 'classcat';
import { forwardRef } from 'react';

import css from './index.module.scss';
import { InputProps } from '../types';

type Ref = HTMLInputElement;
const Input = forwardRef<Ref, InputProps>(
  ({ checked, helpText, hideLabel, id, label, link, onChange, name, required }, ref) => {
    const forId = id || `input-${name}`;
    const inputProps = {
      checked,
      className: css.input,
      id: forId,
      name,
      ref,
      required,
    };
    return (
      <>
        <label className={css.root} htmlFor={forId}>
          <input
            {...inputProps}
            checked={checked}
            onChange={() => {
              onChange({ target: { name, value: !checked } });
            }}
            required={required}
            type="checkbox"
          />
          <div className={cc([css.label, { [css.hideLabel]: hideLabel }])}>{label}</div>
        </label>
        {helpText && <div className={css.helpText}>{helpText}</div>}
        {link && <div className={css.link}>{link}</div>}
      </>
    );
  },
);
export default Input;
