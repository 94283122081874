import { Button, Select } from '@outdoor-voices/ui';
import cc from 'classcat';
import React, { FormEventHandler, ReactNode, useEffect, useState } from 'react';

import Captcha from '../Captcha';
import Input from '../Input';
import InputBirthdate from '../InputBirthdate';
import InputCheckbox from '../InputCheckbox';
import InputHidden from '../InputHidden';
import InputTel from '../InputTel';

import css from './index.module.scss';

type Field = {
  ariaLabel?: string;
  autoComplete?: string;
  checked?: boolean;
  disabled?: boolean;
  helpText?: string;
  half?: boolean;
  label?: string;
  link?: JSX.Element;
  name: string;
  onChange?: (...args: any[]) => any;
  options?: any[];
  placeholder?: string;
  required?: boolean;
  tabIndex?: number;
  type?: string;
  value?: string;
};
export type FieldSet = {
  key: string;
  fields: Field[];
};
type FieldErrors = {
  property: string;
  errors: string[];
};
type FormProps = {
  actionsChildren?: ReactNode;
  additionalButtons?: ReactNode[] | ReactNode | boolean;
  errors?: FieldErrors[];
  error?: string;
  fieldsets: FieldSet[];
  onSubmit: FormEventHandler;
  submitted: boolean;
  submitting: boolean;
  submitLabel: string;
  submitADA: string;
};

const HIDDEN = 'hidden';

const getField = (field: Field): any => {
  switch (field.type) {
    case 'captcha':
      return <Captcha onChange={field.onChange} />;
    case 'checkbox':
      return <InputCheckbox {...field} />;
    case 'date':
      return <InputBirthdate {...field} />;
    case HIDDEN:
      return <InputHidden {...field} />;
    case 'select':
      // @ts-expect-error should have required fields
      return <Select {...field} />;
    case 'tel':
      return <InputTel {...field} />;
    default:
      return <Input {...field} />;
  }
};

const Form: React.FC<FormProps> = ({
  actionsChildren,
  additionalButtons,
  error,
  errors,
  fieldsets,
  onSubmit,
  submitted,
  submitting,
  submitLabel,
  submitADA,
}) => {
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    let interval = null;
    if (submitted) {
      setSaved(true);
      interval = setTimeout(() => {
        setSaved(false);
      }, 1500);
    }
    return () => {
      clearInterval(interval);
    };
  }, [submitting, submitted]);

  const text = saved ? 'Submitted!' : submitting ? 'Doing Things' : submitLabel;
  return (
    <form
      className={cc([css.root, { [css.shake]: error || (errors && errors.length > 0) }])}
      method="post"
      onSubmit={onSubmit}
    >
      {fieldsets.map(({ fields, key }) => (
        <div className={cc([css.fieldset, { [css.hidden]: key === HIDDEN }])} key={key}>
          {fields.map((field) => (
            <div className={cc([css.field, { [css.half]: field.half }])} key={field.name}>
              {getField(field)}
            </div>
          ))}
        </div>
      ))}
      {error && <p className={css.error}>{error}</p>}
      {errors && (
        <ul className={css.errors}>
          {errors.map(({ property, errors: errs }) => (
            <li key={property}>
              <label className={css.error} htmlFor={`input-${property}`}>
                {errs.join(', ')}
              </label>
            </li>
          ))}
        </ul>
      )}
      <div className={css.actions}>
        <div className={css.submit}>
          <Button ariaLabel={submitADA} disabled={submitting || saved} big type="submit">
            {text}
          </Button>
        </div>
        {additionalButtons && <div className={css.additionalButtons}>{additionalButtons}</div>}
        {actionsChildren && actionsChildren}
      </div>
    </form>
  );
};
export default Form;
