import { forwardRef } from 'react';
import cc from 'classcat';

import { InputProps } from '../types';
import css from './index.module.scss';

type Ref = HTMLInputElement;
const Input = forwardRef<Ref, InputProps>(
  (
    {
      autoComplete,
      helpText,
      hideLabel,
      id,
      label,
      link,
      onChange,
      onFocus,
      name,
      placeholder,
      required,
      type,
      value,
      withIcon,
    },
    ref,
  ) => {
    const forId = id || `input-${name}`;
    const rootClass = cc([css.root, { [css.withIcon]: withIcon }, { [css.radioLabel]: type === 'radio' }]);
    const inputProps = {
      className: css.input,
      id: forId,
      link,
      name,
      onChange,
      onFocus,
      placeholder,
      ref,
      type,
      required,
      value,
    };
    if (autoComplete) {
      inputProps['autoComplete'] = autoComplete;
    }
    return (
      <>
        <label className={rootClass} htmlFor={forId}>
          <div className={cc([css.label, { [css.hideLabel]: hideLabel }])}>{label}</div>
          <div className={css.inputWrap}>
            <input {...inputProps} />
          </div>
        </label>
        {helpText && <div className={css.helpText} dangerouslySetInnerHTML={{ __html: helpText }} />}
        {link && <div className={css.link}>{link}</div>}
      </>
    );
  },
);
/* eslint-disable @typescript-eslint/no-empty-function */
Input.defaultProps = {
  autoComplete: '',
  id: '',
  onFocus: () => {},
  placeholder: '',
  required: false,
  type: 'text',
  value: '',
};
export default Input;
