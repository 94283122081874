import { forwardRef } from 'react';

import { InputProps } from '../types';

type Ref = HTMLInputElement;
const Input = forwardRef<Ref, InputProps>(({ autoComplete, id, name, value, tabIndex }, ref) => {
  const forId = id || `input-${name}`;

  const inputProps = {
    id: forId,
    name,
    ref,
    tabIndex,
    value,
  };
  if (autoComplete) {
    inputProps['autoComplete'] = autoComplete;
  }
  return <input {...inputProps} type="text" readOnly />;
});
export default Input;
