import { Select } from '@outdoor-voices/ui';
import { useRef, useState } from 'react';

import { InputProps } from '../types';
import { MONTHS } from '../../config/constants';
import css from './index.module.scss';

const numDays = (month: number) => {
  if ([4, 6, 9, 11].indexOf(month) > -1) {
    return 30;
  }
  if (month === 2) {
    return 29;
  }
  return 31;
};
const pad = (num: number): string => (num < 10 ? `0${num}` : String(num));

const InputBirthdate: React.FC<InputProps> = ({ label, helpText, onChange, name, required, value: initialValue }) => {
  const vals = initialValue ? String(initialValue).split('/') : [];
  const monthSelectRef = useRef<HTMLDivElement>(null);

  const [month, setMonth] = useState(vals[0] ? parseInt(vals[0], 10) : null);
  const [days, setDays] = useState(month ? numDays(month) : 31);
  const [day, setDay] = useState(vals[1] ? parseInt(vals[1], 10) : null);

  const onMonthChange = ({ target: { value } }) => {
    const newDays = numDays(parseInt(value, 10));
    if (day > newDays) {
      setDay(1);
    }
    setMonth(value);
    setDays(newDays);
    onChange({ target: { name, value: `${value}/${day}` } });
  };
  const onDayChange = ({ target: { value } }) => {
    setDay(value);
    onChange({ target: { name, value: `${month}/${value}` } });
  };
  const dayOptions = Array(days)
    .fill(0)
    .map((_, idx) => ({ label: pad(idx + 1), value: String(idx + 1) }));

  const onLabelClick = () => {
    monthSelectRef.current?.querySelector('button').click();
  };

  return (
    <>
      <button className={css.label} type="button" onClick={onLabelClick}>
        {label}
      </button>
      <div className={css.fields}>
        <div className={css.field} ref={monthSelectRef}>
          <Select
            autoComplete="bday-month"
            hideLabel
            label="Month"
            onChange={onMonthChange}
            name={`${name}-month`}
            placeholder="Month"
            required={required}
            value={month ? String(month) : ''}
            options={MONTHS.map(({ label, value }) => ({ label, value: String(value) }))}
          />
        </div>
        <div className={css.field}>
          <Select
            autoComplete="bday-day"
            hideLabel
            label="Day"
            name={`${name}-day`}
            onChange={onDayChange}
            options={dayOptions}
            placeholder="Day"
            required={required}
            value={day ? String(day) : ''}
          />
        </div>
      </div>
      {helpText && <div className={css.helpText}>{helpText}</div>}
    </>
  );
};

export default InputBirthdate;
