import dynamic from 'next/dynamic';

const ReCAPTCHA = dynamic(() => import('react-google-recaptcha'), {
  ssr: false,
});

type Props = {
  onChange: (value: string) => void;
};

const Input: React.FC<Props> = ({ onChange }) => (
  <ReCAPTCHA onChange={onChange} sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY} />
);

export default Input;
